import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../fbase";

const ReserveRegisterPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [reservation, setReservation] = useState<any>(null);
  const [phone, setPhone] = useState("");

  const fetchReservationByCode = async (Code: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/code/"+Code)
      .then((res) => {
        setReservation(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchReservationByCode(Number(params.Code));
  }, []);


  const fetchUpdateReservationInfo = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/api/user_reserve",{
      "space_id": reservation.space.id,
      "code": params.Code,
      "phone": phone,
      "token": token
    })
    .then((res) => {
      if(res.data.result == true) {
        navigate('../result');
      } else {
        console.log("ERROR!");
      }
    })
    .catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex justify-center shadow-lg">
        <div className="flex max-w-6xl w-full justify-between items-center px-4 py-2">
          <p></p>
          <p className="font-bold text-2xl">{reservation!=null?reservation.space.name:""}</p>
          <p></p>
        </div>
      </div>
      {/* <p className="text-6xl font-black p-8">{reservation!=null?reservation.number:""}</p> */}
      <p>약관</p>
      <div className="flex">
        <input type="checkbox"/>
        <p>약관에 동의합니다.</p>
      </div>
      <div className="flex">
        <p>연락처 : </p>
        <input type="text" placeholder="000-0000-0000"
          onChange={(e)=>setPhone(e.target.value)}/>
      </div>
      <div onClick={fetchUpdateReservationInfo}>다음</div>
    </div>
  );
};

export default ReserveRegisterPage;