import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const AdminSpacePage = () => {

  const params = useParams();

  const [space, setSpace] = useState<any>(null);
  const [waitingList, setWaitingList] = useState<any>([]);
  const [reserveList, setReserveList] = useState<any>([]);

  const fetchSpaceById = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+spaceId)
      .then((res) => {
        setSpace(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchCallNext = async () => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/call_next/"+params.spaceId)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchWaitingList = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/waiting_list/"+spaceId)
      .then((res) => {
        console.log(res);
        setWaitingList(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchReserveList = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/space/"+spaceId)
      .then((res) => {
        console.log(res);
        setReserveList(res.data.result.reverse());
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
  //   axios.put("http://localhost:8080/api/change_space_status", {
  //     "spaceId": spaceId,
  //     "status": status
  //   })
  //     .then((res) => {
  //       fetchSpaceListByUserId(adminId);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  useEffect(() => {
    fetchSpaceById(Number(params.spaceId));
    fetchWaitingList(Number(params.spaceId));
    fetchReserveList(Number(params.spaceId));
    // fetchSpaceListByUserId(adminId);
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex justify-center shadow-lg">
        <div className="flex max-w-6xl w-full justify-between items-center px-4 py-2">
          <p></p>
          <p className="font-bold text-2xl">{space?.name}</p>
          <p></p>
        </div>
      </div>
      <div className="flex flex-col max-w-4xl">
        
        <img src="https://via.placeholder.com/1800x600"></img>
        <Link to='edit'>
          <div>
            정보 수정하기
          </div>
        </Link>
        <Link to='qrcode'>
          <div>
            QR Page
          </div>
        </Link>
        <div>
          <p>대기열</p>
          <div className="flex flex-col px-2">
            <p onClick={fetchCallNext}>다음 번호 호출</p>
            {
              waitingList.map((e: any) => (
                <div className="flex">
                  <p className="w-12">{e.number}</p>
                  <p>{e.phone}</p>
                </div>
              ))
            }
          </div>
        </div>
        <div>
          예약 내역 확인
        </div>
        <div className="flex flex-col px-2">
          {
            reserveList.map((e: any) => (
              <div className="flex">
                <p className="w-12">{e.number}</p>
                <p className="w-24">{e.status}</p>
                <p>{e.phone}</p>
              </div>
            ))
          }
        </div>
      </div>

    </div>
  );
};

export default AdminSpacePage;