import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const AdminSpaceQRCodePage = () => {
  const params = useParams();

  const [space, setSpace] = useState<any>(null);
  const [code, setCode] = useState<string>("");

  const [reload, setReload] = useState<any>(null);

  const fetchReloadQRCode = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/signal/"+spaceId, {timeout: 10000})
    .then((res) => {
      console.log(res);
      fetchQRCode(spaceId);
      fetchReloadQRCode(spaceId);
    })
    .catch((e) => {
      console.log(e);
      fetchReloadQRCode(spaceId);
    });
  }

  const fetchSpaceById = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+spaceId)
      .then((res) => {
        setSpace(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchQRCode = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/get_qrcode/"+spaceId)
      .then((res) => {
        setCode(res.data.result);
        console.log(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const testAsync = async () => {
    axios.post(process.env.REACT_APP_BACKEND_API_URL+"/signal/"+Number(params.spaceId), 'message=1');
  }

  useEffect(() => {
    fetchSpaceById(Number(params.spaceId));
    fetchQRCode(Number(params.spaceId));
    setReload(new Date());
    fetchReloadQRCode(Number(params.spaceId));
  }, []);

  return (
    <div className="flex flex-col h-screen items-center justify-center gap-4  ">
      <p className="text-3xl font-bold text-center">{space?.name}</p>
      <div className=" bg-red-50">
        <QRCodeSVG
          value={window.location.origin+"/reserve/"+code}
          className="w-72 h-fit"></QRCodeSVG>
      </div>
      <Link to={window.location.origin+"/reserve/"+code}>
        <div>
          {window.location.origin+"/reserve/"+code}
        </div>
      </Link>
      <p className="text-3xl font-bold text-center">{space?.description}</p>
      <p onClick={testAsync}>test</p>
    </div>
  );
};

export default AdminSpaceQRCodePage;