import { Link } from "react-router-dom";
import Header from "../components/_header";

const HomePage = () => {
  return (
    <div className="flex flex-col w-full">
      <Header />
      <img src="https://via.placeholder.com/1800x300"></img>
      {/* <Link to='/u/login'>
        <div>
          Login
        </div>
      </Link> */}
      {/* <Link to='space'>
        <div>
          Space
        </div>
      </Link> */}
      <Link to='admin'>
        <div>
          Admin
        </div>
      </Link>
      {/* <Link to='mypage'>
        <div>
          MyPage
        </div>
      </Link> */}
    </div>
  );
};

export default HomePage;