import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ReserveResultPage = () => {
  const params = useParams();

  const [reservation, setReservation] = useState<any>(null);
  const [phone, setPhone] = useState("");

  const fetchReservationByCode = async (Code: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/code/"+Code)
      .then((res) => {
        setReservation(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchReservationByCode(Number(params.Code));
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex justify-center shadow-lg">
        <div className="flex max-w-6xl w-full justify-between items-center px-4 py-2">
          <p></p>
          <p className="font-bold text-2xl">{reservation!=null?reservation.space.name:""}</p>
          <p></p>
        </div>
      </div>
      <p className="text-6xl font-black p-8">{reservation!=null?reservation.number:""}</p>
    </div>
  );
};

export default ReserveResultPage;