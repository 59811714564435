import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../fbase";
import { onAuthStateChanged } from "firebase/auth";
import Header from "../../components/_header";

const AdminPage = () => {
  const navigate = useNavigate();

  const [spaceList, setSpaceList] = useState<[]>([]);

  const fetchSpaceListByAdmin = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/admin/" + token)
      .then((res) => {
        setSpaceList(res.data.result);

        // let newAdvertisements:Advertisement[] = [];
        // res.data.map((e: any, i: number) => {
        //     let newAdvertisement = {
        //         link: e.url,
        //         image: e.image,
        //         desc: e.title,
        //     }
        //     // console.log(newAdvertisement);
        //     newAdvertisements.push(newAdvertisement);
        // })
        // setAdvertisements(newAdvertisements);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
    axios.put(process.env.REACT_APP_BACKEND_API_URL+"/api/change_space_status", {
      "spaceId": spaceId,
      "status": status
    })
      .then((res) => {
        fetchSpaceListByAdmin();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const createSpace = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/space/admin", {
      "token": token,
      "name": "",
      "description": "",
      "status": "CLOSE",
    })
    .then((res) => {
      console.log(res);
      navigate("/admin/space/"+res.data.id+"/edit")
      fetchSpaceListByAdmin();
    })
    .catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {  // 🔥 3
      if(user === null) {
        alert('로그인이 필요한 페이지입니다.');
        // navigate('/');
      }
      fetchSpaceListByAdmin();
    });
  }, []);

  return (
    <div className="flex flex-col w-full">
      <Header />
      <div className="max-w-4xl ">
        <p>공간 목록 List</p>
        <div>
          {
            spaceList.map((e: any, i: number) => {
              return (
                <div key={'space_'+i} className="flex gap-2">
                  {e.id}
                  <Link to={'space/'+e.id}>
                    <div className="w-32">
                      {e.name}
                    </div>
                  </Link>
                  <div className="flex gap-2">
                    <div>{e.status}</div>
                    <div className="flex gap-2 bg-gray-200">
                      {
                        ["OPEN", "CLOSE", "PAUSE"].map((e2:any, i2:number) => {
                          return (
                            <div onClick={() => fetchChangeSpaceStatus(e.id, e2)}>
                              {e2}
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div>alarm</div>
                </div>
              );
            })
          }
          <div onClick={createSpace}>
            공간 추가
          </div>
        </div>
      </div>
    </div>
  );
  };
  
  export default AdminPage;