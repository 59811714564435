import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoginModal from "./LoginModal";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../fbase";
import axios from "axios";

type UserInfo = {
  name: string,
  email: string,
  phone: string,
};

const Header = () => {

  const location = useLocation();

  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const fetchUserInfo = (idToken: string) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/user/token/"+idToken)
        .then((res) => {
          setUserInfo({
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone,
          });
        })
        .catch((e) => {
            console.log(e);
        });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {  // 🔥 3
      if(user !== null) {
        user.getIdToken().then((e)=>{
          fetchUserInfo(e);
        })
      }
      console.log(user);
    });
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    window.location.reload();
  }

  return (
    <div>
      <div className="w-full flex justify-center shadow-lg">
        <div className="flex max-w-6xl w-full justify-between items-center px-4 py-2">
          <p></p>
          <p className="font-bold text-2xl">Pick-Time</p>
          {
            userInfo === null ? (
              <div onClick={() => setModalStatus(true)} className="text-lg font-bold">
                로그인
              </div>
            ) : (
              <div onClick={handleLogout} className="text-lg font-bold">
                로그아웃
              </div>
            )
          }
          <LoginModal status={modalStatus} setStatus={setModalStatus} />
        </div>
      </div>
    </div >
  );
};

export default Header;